import React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Typography, Box } from '@material-ui/core'
import { styles } from './styles'
import { Link } from 'gatsby'
import heart from '../../../assets/images/heart_unfilled.svg'

interface BoardEmptyProps {
  t: TFunction
  hasUsername: boolean
}

const BoardEmpty = ({ t, hasUsername }: BoardEmptyProps) => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      {hasUsername && (
        <Box className={classes.emptyContainer}>
          <Typography variant="h1" className={classes.emptyTitle}>
            Shopping board
          </Typography>
          <Typography className={classes.text} variant="subtitle1">
            {t('shoppingBoard.addProducts1')}
          </Typography>
          <Typography
            className={classes.text}
            variant="subtitle1"
            component="p"
          >
            here's how:
          </Typography>
          <ul>
            <li>
              <Typography
                className={classes.text}
                variant="subtitle1"
                component="p"
              >
                add products from our{' '}
                <Link to={'/category/fashion'} className={classes.customLink}>
                  shop pages
                </Link>{' '}
                to your shopping board. simply press{' '}
                <img width={30} src={heart} alt="heart-icon" /> on each product
                you love.
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.text}
                variant="subtitle1"
                component="p"
              >
                use our browser extension to add products while on{' '}
                <Link to={'/brands'} className={classes.customLink}>
                  any chirpyest friendly retailer site.
                </Link>
              </Typography>
            </li>
            <li>
              <Typography
                className={classes.text}
                variant="subtitle1"
                component="p"
              >
                using the{' '}
                <Link
                  to={'/user/share-with-friends'}
                  className={classes.customLink}
                >
                  “share with friends”
                </Link>{' '}
                tool to add products to your shopping boad
              </Typography>
            </li>
          </ul>
          <Typography
            className={classes.text}
            variant="subtitle1"
            component="p"
          >
            share your shopping board with friends/family/followers. earn cash
            back when anyone shops your product finds!
          </Typography>
        </Box>
      )}
      {!hasUsername && (
        <Box className={classes.emptyContainer}>
          <Typography variant="h1" className={classes.emptyTitle}>
            Shopping board
          </Typography>
          <Typography className={classes.text} variant="h3" component="p">
            {t('shoppingBoard.yourProfile')}{' '}
            <Link to={'/user/dashboard'} className={classes.customLink}>
              {t('shoppingBoard.accountSettings')}
            </Link>{' '}
            {t('shoppingBoard.toUseFeature')}
          </Typography>
        </Box>
      )}
    </div>
  )
}
export default withTranslation()(BoardEmpty)
